<template>
  <div>
    <div class="address-list">
      <div class="address-list-wrap">
        <div class="address-item" v-if="shippingMethod != null">
          <div>
            {{ shippingMethod.firstname }} {{ shippingMethod.lastname }}
          </div>
          <div v-if="shippingMethod.company != null">
            {{ shippingMethod.company }}
          </div>
          <div>{{ shippingMethod.street }}</div>
          <div>{{ shippingMethod.postcode }} {{ shippingMethod.city }}</div>
          <div v-if="shippingMethod.country_code">
            <span v-if="shippingMethod.country_code == 'NL'">Nederland</span>
            <span v-else-if="shippingMethod.country_code == 'BE'">Belgie</span>
            <span v-else-if="shippingMethod.country_code == 'DE'">Germany</span>
          </div>
          <div>
            T:
            <a :href="`tel:` + shippingMethod.telephone">{{
              shippingMethod.telephone
            }}</a>
          </div>
          <div v-if="shippingMethod.vat_id != null">
            VAT:{{ shippingMethod.vat_id }}
          </div>
        </div>
      </div>
    </div>

    <div class="shiping-method">
      <span class="section-title">{{ $t("select_shipping_method") }}</span>
      <div v-if="deliveryOptions.length == 0">
        {{ $t("fill_address_information") }}
      </div>
      <ul class="delivery-options-list">
        <li
          v-for="(deliveryOption, index) of deliveryOptions"
          :key="`ship-${index}`"
        >
          <b-form-radio
            class="custom-radio"
            v-model="selectedShippingMethod"
            name="deliveryOption"
            :value="`${deliveryOption.carrier_code}|${deliveryOption.method_code}`"
          >
            <div class="d-flex">
              <span
                v-if="deliveryOption.amount.value !== 0"
                class="delivery-price"
                >{{ formatPrice(deliveryOption.amount.value) }}&nbsp;</span
              >
              <span v-else class="delivery-price">
                {{ $t("free_shipping") }}
              </span>

              <span class="d-block">
                {{ deliveryOption.carrier_title.trim() }}
              </span>
            </div>
          </b-form-radio>
        </li>
      </ul>
    </div>

    <div v-if="showPickupLocation">
      <span class="section-title no-border">{{ $t("select_store") }}</span>
      <b-form-select
        v-model="pickupLocation"
        :options="pickupLocations"
        class="mb-3"
        value-field="pickup_location_code"
        text-field="name"
      />
    </div>

    <div>
      <div
        class="address-list store-address"
        v-if="showPickupLocation && selectedPickupLocation"
      >
        <div class="address-list-wrap">
          <div class="address-item" v-if="selectedAddress != null">
            <label>{{ pickupLocationName }}</label>
            <div>{{ selectedAddress.street[0] }}</div>
            <div>{{ selectedAddress.postcode }} {{ selectedAddress.city }}</div>
            <div v-if="selectedAddress.country">
              {{ selectedAddress.countryName }}
            </div>
            <div>
              T:
              <a :href="`tel:` + pickupLocationPhone">{{
                pickupLocationPhone
              }}</a>
            </div>
            <div v-if="selectedAddress.vat_id != null">
              VAT:{{ selectedAddress.vat_id }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from "@config";
import { Logger } from "@storefront/core/lib/logger";

import Cart from "@storefront/core/modules/cart/mixins";

export default {
  name: "CheckoutDelivery",
  mixins: [Cart],
  data: () => ({
    shippingMethod: null,
    selectedShippingMethod: null,
    pickupLocation: "",
    showPickupLocation: false,
    selectedPickupLocation: null,
  }),
  mounted() {
    const shmed = this.getSelectedShippingMethod;
    if (shmed != null) {
      if (shmed.carrier_code == "instore" && shmed.method_code == "pickup") {
        const pickupLocation =
          this.$store.getters["checkout/getSelectedStoreLocation"];

        if (pickupLocation != "") {
          this.showPickupLocation = true;
          this.selectedShippingMethod =
            shmed.carrier_code + "|" + shmed.method_code;
          this.pickupLocation = pickupLocation;
        }
      } else {
        this.selectedShippingMethod =
          shmed.carrier_code + "|" + shmed.method_code;
      }
    }

    const enteredAddress =
      this.$store.getters["checkout/getEnteredShippingAddress"];

    if (enteredAddress != null) {
      this.shippingMethod = { ...enteredAddress };
    }

    if (this.selectedShippingMethod == null) {
      this.$emit("toggleNextDisable", true);
    }
  },
  watch: {
    async pickupLocation(code) {
      if (!code) return;

      const response = await this.$store.dispatch("cart/setPickupLocation", {
        code,
      });
      Logger.debug("pickupLocation", "CheckoutDelivery", response)();
      if (response) {
        const delOption = this.selectedShippingMethod.split("|");
        if (this.$gtm.enabled()) {
          let prod = [];
          let items = [];
          this.cartItems.forEach((element) => {
            let i = {
              id: element.product.sku,
              name: element.product.name,
              quantity: element.quantity,
            };
            let it = {
              item_id: element.product.sku,
              item_name: element.product.name,
              quantity: element.quantity,
              price: element.prices.price.value,
            };
            prod.push(i);
            items.push(it);
          });
          if (!this.$gtm.uaDisabled) {
            //GA event
            window.dataLayer.push({
              event: "eec.checkout",
              ecommerce: {
                checkout: {
                  actionField: {
                    step: 3,
                    option: delOption[0] + "|" + delOption[1],
                    action: "checkout",
                  },
                  products: prod,
                },
              },
            });
          }
          if (this.$gtm.ga4Enabled) {
            //GA4 event
            window.dataLayer.push({
              event: "add_shipping_info",
              ecommerce: {
                add: {
                  currency: "EUR",
                  value: this.cartTotalWithOutTax,
                  shipping_tier: delOption[0] + "|" + delOption[1],
                  items: items,
                },
              },
            });
          }
        }
        await this.$store.dispatch("cart/addShippingMethod", {
          carrier: delOption[0],
          method: delOption[1],
        });
        this.selectedPickupLocation = await this.$store.getters[
          "cart/getShippingAddress"
        ];

        this.$store.commit("checkout/setSelectedStoreLocation", code);

        this.$emit("toggleNextDisable", false);
      }
    },
    async selectedShippingMethod(value) {
      const [carrier_code, method_code] = value.split("|");

      if (carrier_code == "instore" && method_code == "pickup") {
        this.showPickupLocation = true;

        const pickupLocation =
          this.$store.getters["checkout/getSelectedStoreLocation"];

        if (pickupLocation != "") {
          this.selectedShippingMethod = carrier_code + "|" + method_code;
          this.pickupLocation = "";
          setTimeout(() => {
            this.pickupLocation = pickupLocation;
          }, 100);
        } else {
          this.$emit("toggleNextDisable", true);
        }
      } else {
        this.showPickupLocation = false;

        const shmed = this.getSelectedShippingMethod;

        if (
          shmed != null &&
          shmed.carrier_code == carrier_code &&
          shmed.method_code == method_code
        ) {
          return;
        }

        const oldAddress =
          this.$store.getters["checkout/getEnteredShippingAddress"];

        if (oldAddress != null) {
          await this.$store.dispatch("cart/addShippingAddress", {
            address: oldAddress,
          });

          this.$store.dispatch("cart/addShippingMethod", {
            carrier: carrier_code,
            method: method_code,
          });
          this.$emit("toggleNextDisable", false);
          if (this.$gtm.enabled()) {
            let prod = [];
            let items = [];
            this.cartItems.forEach((element) => {
              let i = {
                id: element.product.sku,
                name: element.product.name,
                quantity: element.quantity,
              };
              let it = {
                item_id: element.product.sku,
                item_name: element.product.name,
                quantity: element.quantity,
                price: element.prices.price.value,
              };
              prod.push(i);
              items.push(it);
            });
            if (!this.$gtm.uaDisabled) {
              //GA event
              window.dataLayer.push({
                event: "eec.checkout",
                ecommerce: {
                  checkout: {
                    actionField: {
                      step: 3,
                      option: value,
                      action: "checkout",
                    },
                    products: prod,
                  },
                },
              });
            }
            if (this.$gtm.ga4Enabled) {
              //GA4 event
              window.dataLayer.push({
                event: "add_shipping_info",
                ecommerce: {
                  add: {
                    currency: "EUR",
                    value: this.cartTotalWithOutTax,
                    shipping_tier: value,
                    items: items,
                  },
                },
              });
            }
          }
        }
      }
    },
  },
  computed: {
    selectedAddress() {
      let address = { ...this.$store.getters["cart/getShippingAddress"] };
      if (address.country) {
        if (address.country.code == "NL") address.countryName = "Nederland";
        else if (address.country.code == "BE") address.countryName = "Belgie";
        else if (address.country.code == "DE") address.countryName = "Germany";
      }

      return address;
    },
    deliveryOptions() {
      let options = [...this.$store.getters["cart/getShippingMethods"]];

      const pickIndex = options.findIndex(
        (option) => option.carrier_code === "instore"
      );
      options.push(options.splice(pickIndex, 1)[0]);

      return options;
    },
    pickupLocations() {
      let locations = [...this.$store.getters["cart/getPickupLocations"]];
      locations = locations.map((location) => {
        if (location.name.startsWith("EP:Tummers")) return location;
        return {
          ...location,
          name: `EP:Tummers ${location.name}`,
          phone: location.phone,
        };
      });

      let newLocations = [];

      // let sortVal = ["roermond","Panningen","tegelen","echt","heythuysen","horst","weert","sittard",];

      let sortVal = config.pickupSortOptions;
      sortVal.forEach((item) => {
        const index = locations.findIndex(
          (location) =>
            location.pickup_location_code.toLowerCase() == item.toLowerCase()
        );
        if (index > -1) newLocations.push(locations.splice(index, 1)[0]);
      });

      newLocations = [...newLocations, ...locations];

      return newLocations;
    },
    pickupLocationName() {
      return this.pickupLocations.find(
        (loc) => loc.pickup_location_code === this.pickupLocation
      ).name;
    },
    pickupLocationPhone() {
      return this.pickupLocations.find(
        (loc) => loc.pickup_location_code === this.pickupLocation
      ).phone;
    },
    getSelectedShippingMethod() {
      const shmed = this.$store.getters["cart/getShippingMethod"];
      Logger.debug("selectedShippingMethod-get", "checkOut", shmed)();
      return shmed;
    },
    user() {
      return this.$store.getters["user/getCurrentUser"];
    },
    isLoggedIn() {
      return this.$store.getters["user/getIsLoggedIn"];
    },
  },
  methods: {
    formatPrice(price) {
      if (price % 1 === 0) {
        return "€ " + price + ",-";
      }
      return "€ " + price.toFixed(2).replace(".", ",");
    },
  },
};
</script>
