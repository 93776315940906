<template>
  <div>
    <Breadcrumbs />
    <b-modal
      v-if="termsModalContent"
      id="terms_and_condition"
      hide-footer
      class="terms-modal"
      size="lg"
    >
      <div v-html="termsModalContentHtml"></div>
    </b-modal>

    <section class="checkout-page">
      <b-container>
        <b-row class="justify-content-center">
          <b-col sm="11" xl="7">
            <b-row>
              <b-col lg="12">
                <div class="checkout-header">
                  <div
                    class="header-block"
                    :class="{
                      active: steps[0].active,
                      completed: steps[0].completed,
                    }"
                  >
                    <label>{{ $t("facts") }}</label>
                    <div class="icon">
                      <b-icon icon="cart"></b-icon>
                      <b-icon icon="check"></b-icon>
                    </div>
                  </div>
                  <div
                    class="header-block"
                    :class="{
                      active: steps[1].active,
                      completed: steps[1].completed,
                    }"
                  >
                    <label>{{ $t("collection") }}</label>
                    <div class="icon">
                      <b-icon icon="cart"></b-icon>
                      <b-icon icon="check"></b-icon>
                    </div>
                  </div>
                  <div
                    class="header-block"
                    :class="{
                      active: steps[2].active,
                      completed: steps[2].completed,
                    }"
                  >
                    <label>{{ $t("overview") }}</label>
                    <div class="icon">
                      <b-icon icon="cart"></b-icon>
                      <b-icon icon="check"></b-icon>
                    </div>
                  </div>
                  <div
                    class="header-block"
                    :class="{
                      active: steps[3].active,
                      completed: steps[3].completed,
                    }"
                  >
                    <label>{{ $t("pay") }}</label>
                    <div class="icon">
                      <b-icon icon="cart"></b-icon>
                      <b-icon icon="check"></b-icon>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>

            <b-row>
              <b-col lg="12" v-if="current_step === 0">
                <NotLoggedInCheckoutDetails
                  v-if="!isLoggedIn"
                  @toggleNextDisable="toggleNextDisable"
                />
                <LoggedInCheckoutDetails
                  v-else
                  @toggleNextDisable="toggleNextDisable"
                />
              </b-col>

              <b-col v-if="current_step === 1">
                <CheckoutDelivery @toggleNextDisable="toggleNextDisable" />
              </b-col>

              <b-col lg="12" v-if="current_step === 2">
                <div class="">
                  <!-- <span class="group-title d-block"
                ><i class="lnr lnr-checkmark-circle mr-10"></i
                >{{ $t("order_overview") }}</span
              > -->
                  <div class="cart-item-detail">
                    <!-- <b-link
                        href="#"
                        v-b-toggle.cart-details-collapse
                        class="
                          d-block
                          mb-10
                          group-subtitle
                          border-0
                          collapse-opener
                        "
                        >{{
                          $t("items_in_cart", { numberOf: cartCount })
                        }}</b-link
                      > -->
                    <div id="cart-details-collapse" class="">
                      <div class="item-wrap">
                        <div
                          class="single-row"
                          v-for="cartItem of cartItems"
                          :key="cartItem.id"
                        >
                          <div class="left">
                            <div class="img-wrap">
                              <img
                                :src="cartItem.product.image.url"
                                :alt="cartItem.product.name"
                              />
                            </div>
                            <span class="name">
                              <label>{{ cartItem.product.name }}</label>
                              <div
                                v-if="
                                  cartItem.customizable_options &&
                                  cartItem.customizable_options.length
                                "
                              >
                                <div
                                  class="extra-service"
                                  v-for="(
                                    option, idx
                                  ) in cartItem.customizable_options"
                                  :key="idx"
                                >
                                  <label>{{ option.label }}: </label>
                                  <p>
                                    {{ getServiceLabels(option) }}
                                  </p>
                                </div>
                              </div>
                            </span>
                          </div>
                          <div class="right">
                            <div
                              v-if="
                                typeof cartItem.configurable_options !=
                                'undefined'
                              "
                            >
                              <span
                                v-for="(
                                  option, index
                                ) of cartItem.configurable_options"
                                :key="index"
                                class="product-options d-block"
                              >
                                <span class="product-options-title"
                                  >{{ option.option_label }}:&nbsp;</span
                                >
                                <span class="product-options-value">{{
                                  option.value_label
                                }}</span>
                              </span>
                            </div>
                            <div class="d-flex align-items-center">
                              <div>
                                {{
                                  formatPrice(
                                    cartItem.prices.row_total_including_tax
                                      .value
                                  )
                                }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="order-overview">
                    <ul class="cost-list">
                      <li class="">
                        <span class="d-block mr-20">{{ $t("subtotal") }}</span>
                        <span class="d-block">
                          {{ formatPrice(cartSubTotalPrice) }}
                        </span>
                      </li>
                      <li
                        v-for="(price, index) in cartTotalDiscounts"
                        :key="`price-${index}`"
                        class=""
                      >
                        <span class="d-block mr-20">{{ price.label }}</span>
                        <span class="d-block"
                          >- &euro; {{ price.amount.value }}</span
                        >
                      </li>
                      <li v-if="shippingMethod != null" class="">
                        <span class="d-block mr-20"
                          >{{ shippingMethod.carrier_title }} /
                          {{ shippingMethod.method_title }}</span
                        >
                        <span class="d-block">
                          {{
                            formatPrice(
                              shippingMethod.amount_including_tax.value
                            )
                          }}
                        </span>
                      </li>

                      <li
                        v-for="(price, index) in cartTotalTaxPrices"
                        :key="`tax-${index}`"
                        class="d-flex justify-content-between"
                      >
                        <span class="d-block mr-20">{{ price.label }}</span>
                        <span class="d-block"
                          >&euro; {{ price.amount.value }}</span
                        >
                      </li>
                    </ul>
                    <div class="total-grp">
                      <span class="total"
                        >{{ $t("total") }}
                        <span class="totalvatcheckout">
                          {{ $t("totalvat") }}
                        </span></span
                      >
                      <span class="text-blue">
                        {{ formatPrice(cartTotalPrice) }}
                      </span>
                    </div>
                  </div>
                  <div class="add-coupon cst-collapse">
                    <b-link class="collapse-opener" v-b-toggle.coupon-collapse
                      >{{ $t("coupon_discount") }}
                    </b-link>
                    <b-collapse id="coupon-collapse" collapsed>
                      <div class="add-coupon-wrap">
                        <b-form-input
                          v-model="couponCode"
                          type="text"
                          :placeholder="$t('fill_out_discount_code')"
                        ></b-form-input>
                        <b-button @click="addCoupon" variant="primary">{{
                          $t("add")
                        }}</b-button>
                      </div>
                    </b-collapse>
                  </div>
                  <div class="add-notes cst-collapse">
                    <b-link class="collapse-opener" v-b-toggle.notes-collapse
                      >{{ $t("checkout_notes") }}
                    </b-link>
                    <b-collapse id="notes-collapse" collapsed>
                      <div class="add-notes-wrap">
                        <b-form-textarea
                          v-model="customerNotes"
                          @change="addCustommerNote()"
                          :placeholder="$t('checkout_notes_placeholder')"
                          rows="3"
                          max-rows="6"
                        ></b-form-textarea>
                      </div>
                    </b-collapse>
                  </div>
                </div>
              </b-col>

              <b-col lg="12" class="" v-if="current_step === 3">
                <div class="payment-method cst-collapse">
                  <b-link class="collapse-opener" v-b-toggle.payment-collapse
                    >{{ $t("payment_method") }}
                  </b-link>
                  <b-collapse id="payment-collapse" visible>
                    <div
                      class="small text-danger py-2"
                      v-if="selectedShippingMethod == null"
                    >
                      {{ $t("fill_shipping_options") }}
                    </div>
                    <div v-if="selectedShippingMethod != null">
                      <ul class="payment-options-list">
                        <li
                          v-for="(paymentOption, index) in paymentOptions"
                          :key="`pay-${index}`"
                        >
                          <b-form-radio
                            class=""
                            name="paymentOption"
                            :value="paymentOption.code"
                            v-on:change="checkAllSubOptions(paymentOption.code)"
                            v-model="selectedPaymentMethod"
                          >
                            <div class="d-flex align-items-center">
                              <div class="payment-option-img">
                                <img
                                  :src="`${app_url}/media/graphql/icons/50x32/${paymentOption.code}.png`"
                                  :alt="paymentOption.code"
                                />
                              </div>
                              <span>{{ paymentOption.label }}</span>
                            </div>
                          </b-form-radio>
                          <div
                            :class="showPaymentSuboptions"
                            v-for="(
                              paymentSubOption, index
                            ) in paymentOption.additional_fields"
                            :key="`pso-${index}`"
                          >
                            <div
                              v-if="paymentSubOption.type == 'select'"
                              class="pt-4"
                            >
                              <span class="d-block mb-15">{{
                                paymentSubOption.label
                              }}</span>
                              <b-form-group class="select--custom">
                                <b-form-select
                                  :options="paymentSubOption.options"
                                  text-field="label"
                                  :value="
                                    paymentMethodOptions[paymentOption.code][
                                      paymentSubOption.code
                                    ]
                                  "
                                  v-on:change="
                                    setPaymentMethodOptions(
                                      paymentOption.code,
                                      paymentSubOption.code,
                                      $event
                                    )
                                  "
                                >
                                </b-form-select>
                              </b-form-group>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </b-collapse>
                  <div class="require-note" v-if="showPaymentRequired">
                    * {{ $t("terms_required") }}
                  </div>
                </div>
                <div>
                  <div class="terms-checkbox">
                    <b-form-checkbox
                      id="terms"
                      name="terms"
                      label-for="terms"
                      v-model="isTermsSelected"
                      @change="handleTermsChange"
                    >
                    </b-form-checkbox>
                    <label style="cursor: pointer" @click="handleTermsCheck"
                      >{{ $t("agree_checkbox_msg") }}
                      <b-link
                        @click="$bvModal.show('terms_and_condition')"
                        style="cursor: auto"
                      >
                        {{ $t("terms_and_condtion") }}.</b-link
                      ></label
                    >
                  </div>
                  <div class="require-note" v-if="showTermsRequired">
                    * {{ $t("terms_required") }}
                  </div>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="12">
                <div class="step-action">
                  <div class="action-box" v-if="current_step === 0">
                    <b-link @click="$router.push('/checkout/cart')">
                      <b-icon icon="chevron-left"></b-icon>
                      {{ $t("back_to_shopping_cart") }}
                    </b-link>
                  </div>

                  <div class="action-box" v-if="current_step > 0">
                    <b-link @click="prev">
                      <b-icon icon="chevron-left"></b-icon>
                      {{ steps[current_step].prevBtn }}
                    </b-link>
                  </div>

                  <div class="action-box" v-if="current_step <= 2">
                    <b-button
                      :disabled="nextDisable || disabled"
                      @click="next"
                      variant="primary"
                    >
                      {{ steps[current_step].nextBtn }}
                    </b-button>
                  </div>
                  <div class="action-box" v-if="current_step === 3">
                    <b-button
                      @click="submitForm"
                      :disabled="btnDisable"
                      variant="primary"
                      class="text-light w-100 checkout-btn"
                    >
                      <!-- class="text-light w-100 text-uppercase checkout-btn" -->
                      {{ $t("final_order") }}
                    </b-button>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import config from "@config";
import { getCurrentLanguage } from "@storefront/core/i18n";
import { Logger } from "@storefront/core/lib/logger";
// import VRuntimeTemplate from "v-runtime-template";

import Breadcrumbs from "@/base/core/components/common/Breadcrumbs";
import Cart from "@storefront/core/modules/cart/mixins";

import LoggedInCheckoutDetails from "@/base/core/components/checkout/LoggedInCheckoutDetails";
import NotLoggedInCheckoutDetails from "@/base/core/components/checkout/NotLoggedInCheckoutDetails";
import CheckoutDelivery from "@/base/core/components/checkout/CheckoutDelivery.vue";

export default {
  name: "CheckoutPage",
  components: {
    Breadcrumbs,
    LoggedInCheckoutDetails,
    NotLoggedInCheckoutDetails,
    CheckoutDelivery,
    // VRuntimeTemplate,
  },
  mixins: [Cart],
  computed: {
    cartTotalWithOutTax: function () {
      if (this.$store.getters["cart/getCartIsLoaded"] == true) {
        const prices = this.$store.getters["cart/getCartPrices"];
        if (typeof prices != "undefined") {
          if (typeof prices.subtotal_excluding_tax != "undefined") {
            if (typeof prices.subtotal_excluding_tax.value != "undefined") {
              return prices.subtotal_excluding_tax.value;
            }
          }
        }
        return 0;
      } else {
        return 0;
      }
    },
    progress() {
      return Math.round(100 / 4) * this.current_step;
    },
    isLoggedIn() {
      return this.$store.getters["user/getIsLoggedIn"];
    },
    paymentOptions() {
      return this.$store.getters["cart/getPaymentMethods"];
    },
    deliveryOptions() {
      return this.$store.getters["cart/getShippingMethods"];
    },
    paymentMethodOptions() {
      return this.$store.getters["cart/getPaymentMethodOptions"];
    },
    shippingMethod() {
      return this.$store.getters["cart/getShippingMethod"];
    },
    selectedShippingMethod: {
      get: function () {
        const shmed = this.$store.getters["cart/getShippingMethod"];
        Logger.debug("selectedShippingMethod-get", "checkOut", shmed)();
        if (shmed != null) {
          return shmed.carrier_code + "|" + shmed.method_code;
        } else {
          return null;
        }
      },
      set: function (value) {
        const delOption = value.split("|");
        Logger.debug("selectedShippingMethod-set", "checkOut", value)();
        this.$store.dispatch("cart/addShippingMethod", {
          carrier: delOption[0],
          method: delOption[1],
        });
        if (this.$gtm.enabled()) {
          let prod = [];
          let items = [];
          this.cartItems.forEach((element) => {
            let i = {
              id: element.product.sku,
              name: element.product.name,
              quantity: element.quantity,
            };
            let it = {
              item_id: element.product.sku,
              item_name: element.product.name,
              quantity: element.quantity,
              price: element.prices.price.value,
            };
            prod.push(i);
            items.push(it);
          });
          if (!this.$gtm.uaDisabled) {
            //GA event
            window.dataLayer.push({
              event: "eec.checkout",
              ecommerce: {
                checkout: {
                  actionField: {
                    step: 3,
                    option: value,
                    action: "checkout",
                  },
                  products: prod,
                },
              },
            });
          }
          if (this.$gtm.ga4Enabled) {
            //GA4 event
            window.dataLayer.push({
              event: "add_shipping_info",
              ecommerce: {
                add: {
                  currency: "EUR",
                  value: this.cartTotalWithOutTax,
                  shipping_tier: value,
                  items: items,
                },
              },
            });
          }
        }
      },
    },
    selectedPaymentMethod: {
      get: function () {
        return this.$store.getters["cart/getPaymentMethod"];
      },
      set: function (value) {
        this.$store.commit("cart/setPaymentMethod", value);
        if (this.$gtm.enabled()) {
          let prod = [];
          let items = [];
          this.cartItems.forEach((element) => {
            let i = {
              id: element.product.sku,
              name: element.product.name,
              quantity: element.quantity,
            };
            let it = {
              item_id: element.product.sku,
              item_name: element.product.name,
              quantity: element.quantity,
              price: element.prices.price.value,
            };
            prod.push(i);
            items.push(it);
          });
          if (!this.$gtm.uaDisabled) {
            //GA event
            window.dataLayer.push({
              event: "eec.checkout",
              ecommerce: {
                checkout: {
                  actionField: {
                    step: 2,
                    option: value,
                    action: "checkout",
                  },
                  products: prod,
                },
              },
            });
          }
          if (this.$gtm.ga4Enabled) {
            //GA4 event
            window.dataLayer.push({
              event: "add_payment_info",
              ecommerce: {
                add: {
                  currency: "EUR",
                  value: this.cartTotalWithOutTax,
                  payment_type: value,
                  coupon: this.usedCoupon,
                  items: items,
                },
              },
            });
          }
        }
      },
    },
    btnDisable() {
      if (this.$store.getters["cart/getShippingAddress"] == null) {
        return true;
      }
      if (this.$store.getters["cart/getBillingAddress"] == null) {
        return true;
      }
      if (this.$store.getters["cart/getShippingMethod"] == null) {
        return true;
      }
      if (this.$store.getters["cart/getPaymentMethod"] == null) {
        return true;
      }
      if (this.$store.getters["cart/getEmail"] == null) {
        return true;
      }
      if (this.$store.getters["cart/getItemsTotalQuantity"] == 0) {
        return true;
      }
      // if (!this.isTermsSelected) {
      //   return true;
      // }

      return false;
    },
    nextDisable() {
      return (
        this.$store.getters["cart/getShippingAddress"] == null ||
        this.$store.getters["cart/getBillingAddress"] == null
      );
    },
    termsModalContentHtml() {
      let html = this.termsModalContent.content;

      if (this.termsModalContent.content.search("mailto:") == -1) {
        html = this.termsModalContent.content.replace(/<a/g, "<b-link");
        html = this.termsModalContent.content.replace(/<\/a>/g, "</b-link>");
        html = this.termsModalContent.content.replace(/ href="\//g, ' to="');
      }
      return html;
    },
  },
  watch: {
    selectedDeliveryOption() {
      Logger.debug(
        "selectedDeliveryOption",
        "checkout",
        this.selectedDeliveryOption
      )();
      if (this.selectedDeliveryOption != null) {
        const delOption = this.selectedDeliveryOption.split("|");
        this.$store.dispatch("cart/addShippingMethod", {
          carrier: delOption[0],
          method: delOption[0],
        });
      }
    },
    selectedPaymentMethod: {
      handler: function (method) {
        if (method === "paynl_payment_ideal") {
            this.checkAllSubOptions(method);
        }
      },
      immediate: true,
    },
    current_step(step) {
      if (step != 2) this.disabled = false;
    },
  },
  async mounted() {
    const bcrumb = { current: this.$t("checkout"), routes: [] };
    this.$store.commit("breadcrumbs/set", bcrumb);
    if (this.$gtm.enabled()) {
      let prod = [];
      let items = [];
      this.cartItems.forEach((element) => {
        let i = {
          id: element.product.sku,
          name: element.product.name,
          quantity: element.quantity,
        };
        let it = {
          item_id: element.product.sku,
          item_name: element.product.name,
          quantity: element.quantity,
          currency: "EUR",
          price: element.prices.price.value,
        };
        items.push(it);
        prod.push(i);
      });
      if (!this.$gtm.uaDisabled) {
        //GA event
        window.dataLayer.push({
          event: "eec.checkout",
          ecommerce: {
            checkout: {
              actionField: {
                step: 1,
                option: "",
                action: "checkout",
              },
              products: prod,
            },
          },
        });
      }
      if (this.$gtm.ga4Enabled) {
        //GA4 event
        window.dataLayer.push({
          event: "begin_checkout",
          ecommerce: {
            add: {
              currency: "EUR",
              value: this.cartTotalWithOutTax,
              items: items,
            },
          },
        });
      }
    }

    this.fetchCheckoutAgreement();

    this.resetPickupLocation();
  },

  methods: {
    async submitForm() {
      if (!this.isTermsSelected) {
        this.showTermsRequired = true;
        return;
      }

      if (!this.isPaymentSelected) {
        this.showPaymentRequired = true;
        return;
      }

      const retval = await this.$store.dispatch("cart/addPaymentMethod");
      Logger.debug("retval", "submitForm", retval)();
      if (retval != false) {
        // Set hash value
        localStorage.removeItem("orderHash");
        if (retval.order.hash) {
          localStorage.setItem("orderHash", retval.order.hash);
        }

        const url = retval.redirect_url.replace(config.app_url, "");
        if (this.$gtm.enabled()) {
          if (typeof window.fbq === "function") {
            const sp = this.$store.getters["cart/getCartPrices"];
            window.fbq("track", "Purchase", {
              currency: "EUR",
              value: sp.grand_total.value,
            });
          }
        }
        // this.$store.dispatch("cart/unLoad");
        if (url.includes("https://")) {
          window.location = url;
        } else {
          this.$store.dispatch("cart/loadCart");
          this.$router.push({
            path: url + "?transactionid=" + retval.order.increment_id,
            params: { hash: retval.order.hash },
          });
        }
      }
      Logger.debug("submitForm", "checkout", retval)();
    },
    async addCoupon() {
      if (this.couponCode != "") {
        const retval = await this.$store.dispatch("cart/addCouponCode", {
          code: this.couponCode,
        });
        Logger.debug("addCoupon", "cart.vue", retval)();
        if (retval == true) {
          const msg = {
            type: "success",
            title: this.$t("coupon_code"),
            text: this.$t("coupon_code_ok", { code: this.couponCode }),
          };
          this.$store.dispatch("messages/sendMessage", { message: msg });
          this.usedCoupon = this.couponCode;
          this.couponCode = "";
        }
      }
    },

    async addCustommerNote() {
      const retval = await this.$store.commit(
        "cart/setComment",
        this.customerNotes
      );
      Logger.debug("addComment", "cart.vue", retval)();
    },

    setPaymentMethodOptions(key, sub, value) {
      Logger.error("loadContentPage", "data-resolver cms", key)();
      Logger.error("loadContentPage", "data-resolver cms", sub)();
      Logger.error("loadContentPage", "data-resolver cms", value)();

      this.$store.commit("cart/updateCartPaymentMethodOptions", {
        key: key,
        sub: sub,
        value: value,
      });
    },
    checkAllSubOptions(code) {
      Logger.error("PaymainChanged", "data-resolver cms", code)();

      // todo implement for general
      if (code == "paynl_payment_ideal") {
        this.showPaymentSuboptions = "collapse show";
      } else {
        this.showPaymentSuboptions = "collapse hidden";
      }

      this.isPaymentSelected = true;
      this.showPaymentRequired = false;
    },
    submitCoupon(e) {
      e.preventDefault();
    },
    prev() {
      this.steps[this.current_step].active = false;
      if (this.current_step > 0) this.current_step--;
      this.steps[this.current_step].completed = false;
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
    next() {
      this.steps[this.current_step].completed = true;
      if (this.current_step < 3) this.current_step++;
      this.steps[this.current_step].active = true;
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
    toggleNextDisable(value) {
      this.disabled = value;
    },
    handleTermsChange(checked) {
      if (checked) {
        this.showTermsRequired = false;
        // this.$bvModal.show("terms_and_condition");
      }
    },
    handleTermsCheck() {
      this.isTermsSelected = true;
    },
    goToCart() {
      this.$router.push("/checkout/cart");
    },
    getServiceLabels(option) {
      return option.values.map((val) => val.label).toString();
    },
    formatPrice(price) {
      if (price % 1 === 0) {
        return "€" + price + ",-";
      }
      return "€" + price.toFixed(2).replace(".", ",");
    },

    async fetchCheckoutAgreement() {
      const lang = getCurrentLanguage();
      const storelang = config.languages[lang];
      const storeview = storelang["storeview"];

      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + config.shop.accessToken,
        Store: storeview,
      };

      const query =
        "{ checkoutAgreements { agreement_id checkbox_text content content_height is_html mode name } }";
      const retval = await axios({
        url: config.shop.graphQLURL + "?query=" + encodeURI(query),
        method: "GET",
        headers: headers,
      }).catch((e) => {
        Logger.error("loadContentPage", "data-resolver cms", e)();
        throw e;
      });

      // console.log("Response", retval);
      Logger.debug("fetchCheckoutAgreement", "retval", retval);

      if (retval.data.data.checkoutAgreements != null) {
        if (retval.data.data.checkoutAgreements.length) {
          this.termsModalContent = retval.data.data.checkoutAgreements[0];
        }
      }
    },
    resetPickupLocation() {
      this.$store.commit("checkout/setSelectedStoreLocation", "");
    },
  },

  data() {
    return {
      steps: [
        {
          name: "first",
          title: "Vertel ons wie je bent en waar je woont",
          nextBtn: "Doorgaan",
          active: true,
          completed: false,
        },
        {
          name: "second",
          title: "Waar mag jou aanwinst naartoe?",
          prevBtn: "Terug naar gegevens",
          nextBtn: "Doorgaan",
          active: false,
          completed: false,
        },
        {
          name: "third",
          title: "Check, check dubbel check! Ziet dit er goed uit?",
          prevBtn: "Terug naar bezorging",
          nextBtn: "Doorgaan",
          active: false,
          completed: false,
        },
        {
          name: "fourth",
          title: "Waar mag jou aanwinst naartoe?",
          prevBtn: "Terug naar overzicht",
          active: false,
          completed: false,
        },
      ],
      current_step: 0,
      disabled: false,
      couponCode: "",
      customerNotes: "",
      app_url: config.app_url,
      emailReg:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      paymentOptionsIsSelected: false,
      showPaymentSuboptions: "collapse hidden",
      paymentSubOptions: {},
      isTermsSelected: false,
      isPaymentSelected: false,
      showPaymentRequired: false,
      showTermsRequired: false,
      termsModalContent: null,
      usedCoupon: "",
    };
  },
  metaInfo() {
    return {
      meta: [
        { charset: "utf-8" },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
      ],
      title: "Checkout",
      // all titles will be injected into this template
    };
  },
};
</script>

<style lang="scss"></style>
